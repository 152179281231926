import { render, staticRenderFns } from "./CalcResult.vue?vue&type=template&id=6b640ee3&scoped=true&"
import script from "./CalcResult.vue?vue&type=script&lang=js&"
export * from "./CalcResult.vue?vue&type=script&lang=js&"
import style0 from "./CalcResult.vue?vue&type=style&index=0&id=6b640ee3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b640ee3",
  null
  
)

export default component.exports